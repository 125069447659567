<!--能源模块--设备页-->
<template>
  <div id="equipment" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="header">
      <div class="header-left">
        <div class="header-condition">
          <span>{{ $t('doorequipment.a2') }}：</span>
          <a-select v-model="tenantId" style="width: 150px" @change="searchParamsChange">
            <a-select-option
              v-for="(itme, index) in tenants"
              :key="index"
              :value="itme.value"
              :title="itme.name"
            >
              {{ itme.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="header-condition">
          <span>{{ $t('energy.enedata002') }}：</span>
          <a-select v-model="deviceType" style="width: 150px" @change="searchParamsChange">
            <a-select-option
              v-for="(itme, index) in typelist"
              :key="index"
              :value="itme.no"
              :title="itme.text"
            >
              {{ itme.text }}
            </a-select-option>
          </a-select>
        </div>
        <div class="header-condition">
          <span>{{ $t('energy.setting.reportGr.kikiName') }}：</span>
          <a-input
            v-model="deviceName"
            :placeholder="namePlaceholder"
             @change="searchParamNameChange"
            style="width: 150px"
          />
        </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px" @click="search"> {{ $t('energy.enedata006') }} </a-button>
      </div>
    </div>
    <div class="emain">
      <div class="emain_content">
        <my-card
          v-for="item in cardlist"
          :key="item.id"
          :cardContent="item"
          @hasVideoChange="hasVideoChange"
        >
        </my-card>
        <div class="empty" v-for="n in 10" :key="'m' + n"></div>
      </div>
    </div>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>

    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import { getTenantList } from "../../api/door";
import { equipmentTypeList, getCardList } from "../../api/energy";
import card from "./components/card";
import cameraDialog from '../monitoring/cameraDialog.vue';
import moment from "moment";
export default {
  name: "equipment",
  data() {
    return {
      loading: false,
      typelist: [],
      deviceType: "",
      deviceName: "",
      options: [],
      tenants: [],
      tenantId: 0,
      cardlist: [],
      icon: [
        {
          name: this.$t('doorequipment.a6'),
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: this.$t('doorequipment.a7'),
          url: require("../../../public/images/door/equipment02.png"),
        },
        {
          name: this.$t('doorequipment.a8'),
          url: require("../../../public/images/door/equipment03.png"),
        },
        {
          name: this.$t('doorequipment.a9'),
          url: require("../../../public/images/door/equipment04.png"),
        },
        {
          name:this.$t('doorequipment.a10'),
          url: require("../../../public/images/door/equipment05.png"),
        },
        {
          name: this.$t('doorequipment.a11'),
          url: require("../../../public/images/door/equipment06.png"),
        },
      ],
      namePlaceholder:'',
      mkind:undefined,
      nchange:false,
      cameraId:0,
      vdialog: false,
      vmode:'1',
      starDate:new moment().startOf('day'),
      endDate:new moment(),
    };
  },
  computed: {
    kind: function () {
      return this.$route.query.kind;
    },
  },
  mounted(){
    this.mkind = this.$route.query.kind;
    console.log("mounted para",this.mkind);
    localStorage.removeItem('search-params'); 
  },
  async activated() {
    let ekind = this.$route.query.kind?this.$route.query.kind:this.mkind;
    this.namePlaceholder = this.$t('energy.setting.reportGr.inputLabel').replace('${label}',this.$t('energy.setting.reportGr.kikiName'));
    let data = {
      kind: ekind,
      dictionaryType: 32,
      siteId:this.$route.query.id,
    };
    console.log("equipmentTypeList para",data);
   this.getTenantList();
   await equipmentTypeList(data)
      .then((res) => {
        // console.log(res);
        let { data } = res;
        // this.typelist = data;
        this.typelist = [{no: "", text: this.$t("energy.enedata472")},...data.iconList];
        this.pointGrs = [{no: "", text: this.$t("energy.enedata472")},...data.pointGrs];
      })
      .catch((err) => {
        console.log(err);
      });
    this.search();     
  },
  methods:{
    getTenantList(){
      this.tenants = [];
      getTenantList(this.$route.query.id)
      .then((res) => {
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.tenants.push({ value: data[i].id, name: data[i].name });
          this.tenantId = this.tenants[0].value;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    searchParamNameChange(e){
      let lastParams =  localStorage.getItem('search-params');
      if(lastParams){
         let params = JSON.parse(lastParams);
         if(params.deviceName !== this.deviceName){
           this.nchange = true;
         }else{
           this.nchange = false;
         }
      } else {
        if(this.deviceName!=''){
          this.nchange = true;
        } else {
          this.nchange = false;
        }
      }
    },
    searchParamsChange(){
        localStorage.removeItem('search-params'); 
        this.search();
    },
    search(){
          let lastParams =  localStorage.getItem('search-params'); 
          let params = {};
          
          if(this.nchange || !lastParams){
            params = {
              clientId: this.$store.getters.clientId,
              deviceName: this.deviceName,
              deviceType: this.deviceType,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: this.tenantId,
            };
            localStorage.setItem('search-params', JSON.stringify(params)); 
          }else{
            params = JSON.parse(lastParams);

            this.deviceName = params.deviceName;
            this.deviceType = params.deviceType;
            this.tenantId   = params.tenantId;
          }
          

          this.loading = true;
          getCardList(params)
            .then((res) => {
              let { data } = res;
              this.cardlist = data;
              // console.log(res);
              console.log("getCardList cardlist:",this.cardlist)
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
      },
      moment,
      // 卡片视频按钮点击回调函数
      hasVideoChange(data){
        this.vdialog = true;
        this.cameraId = data.cameraId;
      },
  },
  components: {
    "my-card": card,
    "camera-dialog":cameraDialog,
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.emain {
  width: 100%;
  height: calc(100% - 90px);
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.emain_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* .emain::after{
  clear: both;
} */
.empty {
  width: 200px;
  height: 1px;
  margin-bottom: 15px;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>